import jwt_decode from 'jwt-decode';
import {Dispatch} from 'redux';

import {axiosService} from '../../services';
import {AppActions} from '../../types';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from '../../types/actions/Auth.actions';
import {AuthUser} from '../../types/models/AuthUser';
import {fetchError, fetchStart, fetchSuccess} from './Common';

export const onSignIn = (body: {email: string; password: string}) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    console.log('onSignIn');
    try {
      const data = await axiosService.signin(body);
      localStorage.setItem('token', data.accessToken);
      localStorage.setItem('refreshToken', data.refreshToken);
      dispatch(setJWTToken(data.accessToken));
      dispatch(loadUserFromToken(data.accessToken));
      dispatch(fetchSuccess());
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onSignUp = (data: any, type: string, history: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      await axiosService.signup(data, type);
      dispatch(fetchSuccess());
      history.push('/signup-complete');
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onForgetPassword = (email: string) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      const res = await axiosService.forgetPassword(email);
      dispatch(fetchSuccess());
      return Promise.resolve(res);
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const onNewPassword = (code: string, password: string, history: any) => {
  return async (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchStart());
    try {
      await axiosService.newPassword(code, password);
      dispatch(fetchSuccess());
      history.push('/signin/select');
    } catch (err: any) {
      dispatch(fetchError(err));
    }
  };
};

export const updateCurrentUser = (user: any): AppActions => ({
  type: UPDATE_AUTH_USER,
  payload: user,
});

export const loadUserFromToken = (token: string): AppActions => ({
  type: UPDATE_AUTH_USER,
  payload: getUserObject(jwt_decode(token)),
});

export const setJWTToken = (token: string | null): AppActions => ({
  type: SET_AUTH_TOKEN,
  payload: token,
});

const getUserObject = (authUser: any): AuthUser => {
  console.log('Auth useeeeeeer');
  console.log(authUser);
  return {
    name: authUser.name,
    username: authUser.username,
    email: authUser.email,
    organization: authUser.organization,
    permissions: authUser.permissions,
    ratings: authUser.ratings ?? undefined,
    roles: authUser.roles,
  };
};

export const onJWTAuthSignout = () => {
  return (dispatch: Dispatch<AppActions>) => {
    dispatch(fetchSuccess());
    setTimeout(() => {
      dispatch({type: SIGNOUT_AUTH_SUCCESS});
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
    }, 500);
  };
};
