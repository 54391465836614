import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import {AccountIntegration} from 'modules/pages/users/user-account/user-account.types';
import React, {useEffect, useState} from 'react';
import {FC} from 'react';

import {styles} from './form-account.styles';
import {useDispatch} from 'react-redux';
import {onInstallAiEld, onUpdateAiEld, setAccountOpen} from 'redux/actions';

interface FormAccountIntegrationsProps {
  services: AccountIntegration[];
}

interface IntegrationFormData {
  clientId: string;
  clientSecret: string;
}

export const FormAccountIntegrations: FC<FormAccountIntegrationsProps> = ({
  services,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [selectedService, setSelectedService] =
    useState<AccountIntegration | null>(null);
  const [formData, setFormData] = useState<IntegrationFormData>({
    clientId: '',
    clientSecret: '',
  });

  const dispatch = useDispatch<any>();

  const handleInstallClick = (service: AccountIntegration) => {
    setSelectedService(service);
    setOpenForm(true);
  };

  const handleFormClose = () => {
    setOpenForm(false);
    setFormData({clientId: '', clientSecret: ''});
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = async () => {
    setIsSubmitting(true);
    setOpenForm(false);

    !selectedService?.status
      ? dispatch(
          onInstallAiEld(
            formData.clientId,
            formData.clientSecret,
            selectedService!.id,
          ),
        ).finally(() => {
          setIsSubmitting(false);
          setFormData({clientId: '', clientSecret: ''});
        })
      : dispatch(
          onUpdateAiEld(
            formData.clientId,
            formData.clientSecret,
            selectedService!.id,
          ),
        ).finally(() => {
          setIsSubmitting(false);
          setFormData({clientId: '', clientSecret: ''});
        });
  };

  const handleClose = () => {
    dispatch(setAccountOpen(false));
  };

  const classes = styles();

  useEffect(() => {
    setFormData({
      clientId: selectedService?.organizationServiceData?.clientId || '',
      clientSecret:
        selectedService?.organizationServiceData?.clientSecret || '',
    });
  }, [selectedService]);

  return (
    <Box flex={1} display='flex' flexDirection='column' justifyContent='center'>
      <Box>
        {services.map((service: AccountIntegration, index: number) => (
          <Box
            key={index}
            mb={{xs: 3, xl: 6}}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}>
            {service.name}
            <Button
              className={classes.btnRoot}
              variant='contained'
              color='primary'
              onClick={() => handleInstallClick(service)}>
              {service.status ? 'Update' : 'Install'}
            </Button>
          </Box>
        ))}
      </Box>

      <Dialog open={openForm} onClose={handleFormClose}>
        <DialogTitle>
          {selectedService?.status ? 'Update' : 'Install'}{' '}
          {selectedService?.name}
        </DialogTitle>
        <DialogContent>
          <Box mt={2}>
            <TextField
              fullWidth
              label='Client ID'
              name='clientId'
              value={formData.clientId}
              onChange={handleInputChange}
              margin='normal'
            />
            <TextField
              fullWidth
              label='Client Secret'
              name='clientSecret'
              value={formData.clientSecret}
              onChange={handleInputChange}
              margin='normal'
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFormClose} color='default'>
            Cancel
          </Button>
          <Button
            onClick={handleFormSubmit}
            color='primary'
            disabled={isSubmitting}>
            {isSubmitting
              ? 'Installing...'
              : selectedService?.status
              ? 'Update'
              : 'Install'}
          </Button>
        </DialogActions>
      </Dialog>

      <Box mb={4} mt={4}>
        <Button
          variant='contained'
          color='default'
          className={classes.btnRoot}
          style={{marginRight: '20px'}}
          onClick={handleClose}>
          Cancel
        </Button>
        <Button
          variant='contained'
          color='primary'
          disabled={isSubmitting}
          className={classes.btnRoot}
          type='submit'>
          Save Changes
        </Button>
      </Box>
    </Box>
  );
};
