/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {HeaderT, NavLinkT} from '../../home.types';

import {useHistory} from 'react-router-dom';

import {NavLink, useLocation} from 'react-router-dom';
import '../../home.styles.css';
import {useWindowMedia} from '../../hooks/useWindowMedia';
import MenuDrawer from '../MenuDrawer/MenuDrawer.component';

const HeaderComponent: React.FC<HeaderT> = ({
  isSticky,
  setIsSticky,
  activeLink,
  setActiveLink,
}) => {
  const {windowLargePlus} = useWindowMedia();
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const location = useLocation().pathname;

  const navigate = useHistory().push;

  useEffect(() => {
    if (setIsSticky) {
      if (!windowLargePlus) return setIsSticky(true);
      const handleScroll = () => {
        if (window.scrollY > 0) {
          setIsSticky(true);
        } else {
          setIsSticky(false);
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [windowLargePlus, setIsSticky]);

  let navLinks: NavLinkT[] = [
    'about',
    'features',
    'sign-up',
    'testimonials',
    'contact',
  ];

  if (location === '/privacy') {
    navLinks = ['home', 'privacy'];
  }

  const handleNavLinkChange = (link: NavLinkT | null) => {
    const sectionIds = [
      'about',
      'features',
      'sign-up',
      'contact',
      'testimonials',
    ];

    if (location === '/privacy') {
      if (link === 'home') navigate('/');
    }

    if (link && sectionIds.includes(link)) {
      setActiveLink(link);
      const element = document.getElementById(`${link}-section`);
      element?.scrollIntoView({behavior: 'smooth'});
    } else if (link === null) {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
    if (drawerIsOpen) setDrawerIsOpen(false);
  };

  const navLinkColor = (link: NavLinkT) => {
    if (link === activeLink && isSticky) return '#e48c0b';
    return '#fff';
  };

  return (
    <header
      style={{display: 'flex'}}
      className={`navBar ${isSticky ? 'sticky' : ''}`}>
      <nav style={{display: 'flex'}} className='container'>
        <a className='logo' onClick={() => handleNavLinkChange(null)}>
          Freightposter
        </a>
        {windowLargePlus ? (
          <div
            style={{display: 'flex', alignItems: 'center'}}
            className='navigation'>
            {navLinks.map((link) => (
              <a
                key={link}
                onClick={() => handleNavLinkChange(link)}
                style={{color: navLinkColor(link)}}>
                {link.toUpperCase()}
              </a>
            ))}
            <div
              style={{display: 'flex'}}
              className='navigation-buttons-wrapper'>
              <NavLink to={'/signin'} className='home-button login-btn'>
                LOGIN
              </NavLink>
            </div>
          </div>
        ) : (
          <MenuDrawer
            handleNavLinkChange={handleNavLinkChange}
            isOpen={drawerIsOpen}
            navLinkColor={navLinkColor}
            navLinks={navLinks}
            setIsOpen={setDrawerIsOpen}
          />
        )}
      </nav>
    </header>
  );
};

export default HeaderComponent;
